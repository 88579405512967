/* Default height for small devices */
#main-bg {
    height: 100vh;
    background-size: cover;
    /* or background-size: contain; */
}

.company-title {
    color: #2c58a0;
    font-weight: bold;
}
.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding: 20px;
  padding: 16px 16px; /* 8 * 8px for the top/bottom, 2 * 8px for the left/right */
  background-color: #f5f5f5; /* Fallback for light grey */
  border-radius: 16px; /* 2 * 8px for border radius */
  margin-bottom: 20px;
}

.contact-form,
.contact-address {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.contact-address h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
  display: flex;
  align-items: center;
}

.contact-address p {
  margin: 10px 0;
}

.icon {
  margin-right: 10px;
  color: #007bff;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: 1fr;
  }
}

/* Optional: Responsive design for smaller screens */
@media (max-width: 768px) {
    .contact-grid {
        grid-template-columns: 1fr;
        /* Stacks all items vertically on small screens */
    }
}

.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%; /* Full width */
  box-sizing: border-box; /* Ensure padding doesn’t affect the width */
}

.about-content {
  display: flex;
  justify-content: space-between; /* Space between the image and text */
  align-items: center;
  width: 100%; /* Full width */
  flex-wrap: wrap; /* Allow wrapping on small screens */
}

.about-image {
  flex: 1;
  /* padding: 20px; */
  margin-top:10px;
}
.about-text {
  flex:1;
  margin-top:35px;
}

.about-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-image img {
  width: 100%; /* Take full width */
  max-width: 600px; /* Max width for larger screens */
  height: auto;
}

@media (max-width: 768px) {
  .about-content {
    flex-direction: column; /* Stack the image and text vertically on smaller screens */
  }

  .about-image, .about-text {
    flex: none;
    width: 100%; /* Ensure full width on smaller screens */
  }

  .about-image img {
    max-width: 100%; /* Ensure the image doesn’t overflow on small screens */
  }
}
.input-bold {
  font-weight: bold;
  color:#007bff;
}