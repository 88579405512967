.custom-margin-top {
    margin-top: 6% !important;
}

.custom-height-setting {
    height: 577px !important;
    overflow-y: auto;
}

.product-modal-img-list {
    max-height: 219px;
    overflow-y: auto;
    margin-top: 10px;

    .relative {
        position: relative;
    }

    .set-relative {
        position: relative;
        height: 134px;
    }

    .custom-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .delete-btn {
        position: absolute;
        right: 0px;
        cursor: pointer;
    }
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #3b71ca;
    color: white;
    width: 100%;
}

.custom-input {
    margin-bottom: 25px;
}

.custom-error {
    color: red;
    position: relative;
    top: -25px;
    left: 10px;
    font-size: 14px;
}

.forgot-password-link{
    color:#2c58a0;
    cursor: pointer;
    float:right;
}
.password-input-wrapper {
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #6c757d;
}
/* Enhanced Styles */
.auth-modal .modal-content {
    border-radius: 1rem !important;
    overflow: hidden;
}

.custom-input-group {
    margin-bottom: 1.5rem;
}

.custom-input-group .form-control {
    padding: 0.75rem 1.5rem;
    border-radius: 2rem !important;
    border: 2px solid #e0e0e0;
}

.custom-input-group .form-control:focus {
    box-shadow: none;
    border-color: #3b71ca;
}

.gradient-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(59, 113, 202, 0.3);
}

.text-primary-hover:hover {
    color: #2a5aa7 !important;
}

.bg-gradient-primary {
    background: linear-gradient(45deg, #3b71ca, #14a44d) !important;
}

.rounded-lg {
    border-radius: 1rem !important;
}