.custom-margin-top {
    margin-top: 6%;
}

.logo-text {
    font-size: 15px;
    display: inline-block;
    margin-left: 10px;
    color:#2c58a0;
    font-weight: bold;
}

.custom-td {
    border-bottom: #eee 1px solid;
    padding: 10px;
}

.no-border {
    border: 0px !important;
}

.mt-20 {
    margin-top: 20px;
}

.top-7 {
    top: 7px;
}

.body-wrap {
    width: 100%;
}

.disabled-certi {
    display: inline-block;
    padding: 6px 12px;
    color: none;
    cursor: not-allowed;
    background-color: #3b71ca;
    width: 100%;
}

.noti-count {
    top: -10px !important;
}

.dropdown-menu {
    max-height: 500px !important;
    overflow-y: auto !important;
}

@media print {
    body * {
        visibility: hidden;
    }

    #printTable,
    #printTable * {
        visibility: visible;
    }

    #printTable {
        position: absolute;
        left: 0;
        top: 0;
    }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
    .custom-margin-top {
        margin-top: 10% !important;
    }
    .text-wrap a {
        white-space: normal;
    }
}

@media (min-width: 200px) and (max-width: 767px) {
    .custom-margin-top {
        margin-top: 20% !important;
    }
    .text-wrap a {
        white-space: normal;
    }
}